import React from 'react'
import { graphql } from 'gatsby'
import Header from '../components/Header'
import MainWrapper from '../containers/MainWrapper'
import ProjectPost from '../components/ProjectPost'
import SEO from '../components/SEO'

class Projets extends React.Component {
  render() {
    const { projects, ordering } = this.props.data
    const projectsOrderItems = ordering.edges[0].node.data.items;
    const orderedProjects = projectsOrderItems.map((object) => {
      const projectIndex = projects.edges.findIndex((project) => {
        return project.node.data.projectid === object.item.uid
      })
      return projects.edges[projectIndex];
    });
    
    return (
      <React.Fragment>
        <SEO 
          title={`Projets`} 
          desc={`Les projets du réalisateur Rémy Disch. Dreamland, 60 secondes & L'étrange histoire du petit homme.`}
          pathname={this.props.path} 
        />
        <Header {...this.props} />
        <MainWrapper>
          {orderedProjects.map((item, index) => {
            return <ProjectPost {...item} key={`post-${index}`} />
          })}
        </MainWrapper>
      </React.Fragment>
    )
  }
}

export default Projets
export const pageQuery = graphql`
  query Realisations {
    projects: allPrismicProjet {
      edges {
        node {
          id
          data {
            projectid
            intro_image {
              alt
              url
            }
            titre {
              text
            }
          }
        }
      }
    }
    ordering: allPrismicOrderitems(filter: {uid: {eq: "projets"}}) {
      edges {
        node {
          data {
            items {
              item {
                uid
              }
            }
          }
        }
      }
    }
  }
`