import React from 'react'
import { PostContainer } from '../containers/Post'
import { Link } from 'gatsby'

const ProjectPost = ({ node: { id, data } }) => {
  const { intro_image, projectid } = data;
  return (
    <React.Fragment>
      <PostContainer className="listing">
        <div className="content">
          <Link to={`/${projectid}/`} className="imageLink">  
            <img src={intro_image.url} alt={intro_image.alt} />
          </Link>
        </div>
      </PostContainer>
    </React.Fragment>
  )
}

export default ProjectPost;